<template>
  <div>
    <div class="table_sty">
      <div class="froms">
        <p class="describe">组织架构</p>
        <div class="segmentation"></div>
      </div>
      <div class="tree-form" style="margin-top: 20px;">
        <el-button type="primary" @click="on_pop_addplus()" icon="el-icon-plus">新建</el-button>
        <el-button type="primary" @click="on_pop_getFromding()" icon="el-icon-plus">同步钉钉</el-button>
        <el-table :data="list_Data" row-key="id" border default-expand-all
          :tree-props="{ children: 'items', hasChildren: 'hasChildren' }">
          <el-table-column prop="name" label="部门名称"> </el-table-column>

          <el-table-column prop="safe_response" label="安全责任人"> </el-table-column>
          <el-table-column prop="safety_officer" label="安全员"> </el-table-column>
          <el-table-column prop="mobile" label="联系电话" width="180"> </el-table-column>

          <el-table-column prop="userCount" label="部门人数" width="80"> </el-table-column>
          <el-table-column prop="name" label="操作">
            <template slot-scope="scope">
              <el-link class="list_sty" type="primary" @click="on_pop_addplus(scope.row.id)">修改</el-link>

              <el-link class="list_sty" type="primary" @click="on_deletes(scope.row.id)">删除</el-link>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!-- 新建修改弹窗 -->
    <el-dialog :title="title" show-close :visible.sync="dialog_show" center>
      <OrganizationalReadct v-if="dialog_show" ref="OrganizationalReadct" :refbool.sync="dialog_show"
        @way_list="way_list" />
    </el-dialog>
  </div>
</template>

<script>
import OrganizationalReadct from "../../components/view_redact/organizational_readct.vue";
import { departmentindex, departmentdelete, getDingDepart } from "../../assets/request/api";
export default {
  name: "organizational_structure",
  components: { OrganizationalReadct },
  data() {
    return {
      title: "",
      dialog_show: false,
      list_Data: [],
    };
  },
  created() {
    this.way_list();
  },
  methods: {
    way_list() {
      departmentindex().then((res) => {
        let { data } = res.data;
        console.log(data);
        this.list_Data = data;
      });
    },
    on_deletes(id) {
      let that = this;
      this.$confirm("是否确定删除节点", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          departmentdelete({ id: id }).then((res) => {
            if (res.code == 0) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              that.way_list();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    on_pop_addplus(id) {
      this.dialog_show = true;
      this.title = id ? "修改" : "新增";
      this.$nextTick(function () {
        this.$refs.OrganizationalReadct.way_show(id);
      });
    },
    on_pop_getFromding() {
      getDingDepart().then((res) => {
        console.log(res);
      })

    }
  },
};
</script>
<style lang="less" scoped>
.describe {
  display: inline-block;
  position: relative;
  padding: 4px;
  font-size: 16px;
  color: #0788ff;
  font-weight: bold;
  padding-bottom: 10px;
}

.describe:before {
  content: "";
  position: absolute;
  bottom: -2px;
  width: 50%;
  height: 4px;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #0788ff;
}

.segmentation {
  height: 1px;
  background-color: #f4f4f4;
  margin-bottom: 20px;
}

.tree-form {
  background: #ffffff;
  padding: 20px;
}

.custom-tree-node {
  display: flex;
  justify-content: space-between;
}

s .operation {
  .isty {
    padding-right: 15px;
  }
}

.custom-tree-node {
  width: 100%;
  padding-bottom: 4px;
  border-bottom: 1px solid #ebecec;
}

.el-tree-node {
  margin: 3px 0;
}

.list_sty {
  margin-right: 10px;
}
</style>
